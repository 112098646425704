import React, { useEffect, useMemo, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "../../components/Toolbar/Toolbar";
import Table from "../../components/Table/Table";
import optionsParser from "../../helpers/optionsParser";
import Loading from "../../components/Loading/Loading";
import Collapse from "@material-ui/core/Collapse";
import Columns from "../../components/Columns";
import Filter from "../../components/Filter/Filter";
import debounce from "lodash.debounce";
import {
  api_delete,
  api_get,
  get_file,
  api_put,
  api_delete_progress,
} from "../../utils/Api";
import { SearchUrlHandler } from "helpers/methods";
import {useStore} from "../../contexts/JWTAuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const TableList = React.memo((props) => {
    const {
    columnsList,
    fieldSearchable,
    fieldFilterable,
    url,
    exportUrl,
    pageLink,
    searchMessage,
    baseUrl,
    noFilter,
    noSearch,
    noColumns,
    noShow,
    cacheUrl,
    resetdeleteProgressUrl,
    noDelete,
    noExport,
    noEdit,
    noAction,
    removeAdd,
    offerAction,
    noPagination,
    noCheck,
    restore,
    deleteUrl,
    noDeleteMany,
    keyValue,
    title,
    noList,
    ...rest
  } = props;
  const { user } = useStore();
  const [search, searchChange] = useState("");
  const [filters, filtersChange] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [columns, columnsChange] = useState(columnsList);
  const [sort, sortChange] = useState({ accessor: "id", order: "desc" });
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(24);
  const [isLoading, setLoading] = useState(false);

  const getData = useCallback(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    setLoading(true);
    const currentUrl = `${url}?page=${page}${window.location.search ? SearchUrlHandler(window.location.search, sort) : options}&perPage=${rowsPerPage}`

    if (url.includes("affiliations") && user.can_display_affiliations || !url.includes("affiliations") || window.location.search || search) {
      api_get(currentUrl).then((data) => {
            setData(data);
            setLoading(false);
          });
    } else {
      setData([]);
      setLoading(false);
    }
  }, [search, filters, sort, fieldSearchable, url, page, rowsPerPage, user]);

  const debouncedFetchData = useMemo(() => debounce(getData, 1000), [getData]);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [search, filters, sort, page, rowsPerPage, debouncedFetchData]);



//   useEffect(() => {
//     if ((noList && Object.keys(filters).length) || !((url.includes("affiliations") && user.can_display_affiliations))) {
//       getData();

//       setFiltersOpen(localStorage.getItem("FilterButton"));
//     }
//   }, [getData, filters, noList, url, user]);

//   const fetchData = () => {
//     if (search.length) {
//       setLoading(true);
//       const options = optionsParser(search, null, sort, fieldSearchable);
//       setIsSearch(true);
//       setPage(1);
//       api_get(`${url}?page=${page}${options}`).then((data) => {
//         setLoading(false);
//         setData(data);
//       });
//     } else if (Object.keys(filters).length) {

//       const options = optionsParser(null, filters, sort, fieldSearchable);
//       setPage(1);
//       api_get(
//           `${url}?page=${page}${window.location.search ? SearchUrlHandler(window.location.search) : options}&perPage=${rowsPerPage}&searchJoin=and`
//       ).then((data) => {
//         setLoading(false);
//         setData(data);
//       });
//     }
//   };


//   const debouncedResults = useMemo(() =>
//           debounce(() => {
//             setIsSearch(!!search.length);
//             setPage(1);

//             if (search.length) {
//               setLoading(true);
//               const options = optionsParser(search, null, sort, fieldSearchable);
//               api_get(`${url}?page=1${options}`).then((data) => {
//                 setLoading(false);
//                 setData(data);
//               });
//             } else if (Object.keys(filters).length) {
//               setLoading(true);
//               const options = optionsParser(null, filters, sort, fieldSearchable);
//               api_get(
//                   `${url}?page=1${window.location.search ? SearchUrlHandler(window.location.search) : options}&perPage=${rowsPerPage}&searchJoin=and`
//               ).then((data) => {
//                 setLoading(false);
//                 setData(data);
//               });
//             }
//           }, 1000),
//       [filters, sort, fieldSearchable, url]
//   );

//   useEffect(() => {
//     debouncedResults();

//     return () => {
//       debouncedResults.cancel();
//     };
//   }, [debouncedResults]);


const toggleFilters = useCallback(() => {
    setFiltersOpen(!filtersOpen);
    localStorage.setItem("FilterButton", !filtersOpen);
  }, [filtersOpen]);

  const toggleColumns = useCallback(() => {
    setColumnsOpen(!columnsOpen);
  }, [columnsOpen]);

const handleSortChange = useCallback((accessor) => {
    sortChange({
              accessor: accessor,
              order: sort.order === "asc" ? "desc" : "asc",
            });
  }, [sort]);



  const getListData = () => {
    setLoading(true);
    const options = optionsParser(null, null, sort, null);
    if ((url.includes("affiliations") && user.can_display_affiliations === true) || !url.includes("affiliations") || window.location.search || search) {
      api_get(
        `${url}?searchJoin=and&page=${page}${options}&perPage=${rowsPerPage}`
      ).then((data) => {
        setLoading(false);
        setData(data);
      });
    } else setData([]);
  };

const handlePageChange = useCallback((event, page) => {
    setPage(page + 1);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);


const handleExport = useCallback(() => {
    const options = optionsParser(search, filters, sort, fieldSearchable);
    get_file(`${exportUrl}?searchJoin=and&page=${page}${options}`).then(() => {});
  }, [search, filters, sort, page, fieldSearchable, exportUrl]);

  const classes = useStyles();
  if (!data && !noList) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Toolbar
        toggleFilters={toggleFilters}
        toggleColumns={toggleColumns}
        searchChange={searchChange}
        pageLink={pageLink}
        searchMessage={searchMessage}
        handleExport={handleExport}
        removeAdd={removeAdd}
        noExport={noExport}
        noSearch={noSearch}
        noColumns={noColumns}
        cacheUrl={cacheUrl}
        cache_method={api_get}
        initializeData={getListData}
        title={title}
      />
      <div className={classes.content}>
        <Collapse in={columnsOpen}>
          <Columns columnsChange={columnsChange} columns={columns} />
        </Collapse>
        <Collapse in={filtersOpen}>
          {noFilter !== true && (
            <Filter
              fields={fieldFilterable}
              values={filters}
              filtersChange={filtersChange}
              initializeData={getListData}
              setData={setData}
            />
          )}
        </Collapse>

        <Table
          columns={columns}
          isLoading={isLoading}
          data={data}
          handleSortChange={handleSortChange}
          sort={sort}
          handlePageChange={handlePageChange}
          page={page}
          tableService={{
            method: restore == true ? api_put : api_delete,
            base_url: `${baseUrl}`,
            cache_method: api_get,
            base_cache_url: `${cacheUrl}`,
            resetProgress_method: api_delete_progress,
          }}
          rowsPerPage={rowsPerPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          pageLink={pageLink}
          initializeData={getData}
          noShow={noShow}
          noPagination={noPagination}
          noCheck={noCheck}
          noDelete={noDelete}
          noAction={noAction}
          noEdit={noEdit}
          offerAction={offerAction}
          deleteUrl={deleteUrl}
          noDeleteMany={noDeleteMany}
          keyValue={keyValue}
        />
      </div>
    </div>
  );
});

export default TableList;
