import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  Box,
 
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useParams} from 'react-router-dom';
import SnackBar from '../../../components/SnackBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {AsyncPaginate} from 'react-select-async-paginate';
import theme from '../../../theme';
import optionsParser from '../../../helpers/optionsParser';
import mapStateToRequest from '../../../helpers/mapStateToRequest';
import FilesDropzone from '../../../components/FilesDropzone';
import queryString from "query-string";
import {api_get,  api_post_serialize} from "../../../utils/Api";
import FileDropZone from "../../../components/FileDropZone";
import FilesList from "../../../components/Toolbar/FilesList";
import DescriptionField from 'components/DescriptionField';
import LoadingApi from 'components/LoadingApi/LoadingApi';
import {getTraineeships, getTeachers, getDivisions, getLevels, getContentTypes, getEvents, getScopes} from "../../../helpers/methods";

const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    'overflow': 'visible',
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },
 
  uploadedFiles: {
    marginTop: 10,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Form = (props) => {
  const {id} = useParams();
  const {className, edit, ...rest} = props;
  const classes = useStyles();
  const [tabs, setTabs] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [levels, setLevels] = useState([]);
  const [division, setDivision] = useState(null);
  const [subject, setSubject] = useState([]);
  const [chapters, setChapters]= useState(null);
  const defaultTab = tabs.find((obj) => obj.name === "Enregistrements");
  const [tab, setTab]= useState({});
  const [tabSection, setTabSection]= useState({});
  const [scope, setScope]= useState({});
  const [type, setType]= useState({});
  const [teacher, setTeacher]= useState({});
  const [selectedChapter, setChapter]= useState(null);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');
  const [open, setOpen] = React.useState(false);
  const [attachments, setAttachments] = useState([]);
  const [eventIdShow, setEventIdShow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    content: {
      name: '',
      description: undefined,
      active: true,
      //type: 6,
      scope: '',
      instructor: '',
      isDraft: false,
      position: 0,
      rtl: false,
      downloadable: false,
      chapterContents: [],
      contentStudentLevels: [],
      isEventTitleApproved: false,
      checkUserLevelRecording: false,
    },
    contentEvent:null
  });
  const query = queryString.parse(window.location.search);
  const eventId=  query && query?.event_id ? query.event_id : '';

  useEffect(() => {
    api_get(`tabs`).then((data) => {
      setTabs(data.payload);
    });
    api_get(`student-levels?page=1`).then((data) => {
      setLevels(data.payload);
    });
    api_get(`content-types`).then((data) => {
      setContentTypes(data.payload);
    });
    if (edit) {
      
      getVideoDatas();
    
    }
    if(eventId)
    {
      setLoading(true)
      //setTab(defaultTab);
      api_get(`events/${eventId}`).then((result) => {
        setLoading(false)
        const data = result.payload;
        const currentDivision = data?.subject_events.length === 1 ? data?.subject_events[0]?.subject?.division : null;
        setDivision(currentDivision);
        const currentSubject = data?.subject_events.length === 1 ? data?.subject_events[0]?.subject : null;
        setSubject(currentSubject);
       setValues({
          content: {
            name: data.content.name,
            description: data.content.description,
            isDraft: data.content.is_draft == true ? 1 : 0,
            isEventTitleApproved: 0,
            active: data?.content?.check_user_level_recording == true ? 1 : 0,
            checkUserLevelRecording: data.content.checkUserLevelRecording == true ? 1 : 0,
            instructor: data.content.instructor,
            chapterContents: data.content.chapter_contents ? data.content.chapter_contents : [],
            scope: data.content.scope,
            contentStudentLevels: data.content?.content_student_levels,
            type: {
              "id": 6,
              "name": "Recording",
              "description": null
            },
            //contentFiles: data.content.content_files,
          },
          //file: data.file,
         traineeship: data.traineeship,
         contentEvent: eventId,
        });
      });
    }
  }, []);

  const getVideoDatas = () => {
    setLoading(true)
    api_get(`content-videos/${id}`).then((result) => {
      setLoading(false)
      const data = result?.payload;
      const tabEdit= data.content?.chapter_contents[0]?.tab? data.content?.chapter_contents[0]?.tab : defaultTab;
      const tabSection= data.content?.chapter_contents[0]?.section? data.content?.chapter_contents[0]?.section?.tab : null;
      const currentChap = data.content.chapter_contents[0] &&  data.content.chapter_contents[0].chapter;
      setTab(tabEdit);
      setTabSection(tabSection);
      setChapter(currentChap);
      setAttachments(data.content.content_files)
      setEventIdShow(data?.event?.id)
      setValues({

        content: {
          name: data.content.name,
          description: data.content.description,
          isDraft: data.content.is_draft == true ? 1 : 0,
          active: data.content.active == true ? 1 : 0,
          checkUserLevelRecording: data?.content?.check_user_level_recording == true ? 1 : 0,
          instructor: data.content.instructor,
          scope: data.content.scope,
          chapterContents: data?.content?.chapter_contents ? data?.content?.chapter_contents : [],
          contentStudentLevels: data.content?.content_student_levels,
          type: data?.content.type,
          isEventTitleApproved: 0,
          //contentFiles: data.content.content_files,
        },
        url : data.url,
        traineeship: data.traineeship,
        contentEvent:data?.event?.id,
      });
    });
  }
  const handleChange = (event) => {
    if (event.target.value){
      setValues({
        ...values, content: {
          ...values.content, [event.target.name]: event.target.value,
        }
      });
    }
  };
  const urlChange = (event) => {
      setValues({...values, [event.target.name]: event.target.value});
  };
  
  const handleDescriptionChange = (value) => {
    setValues((valueState) => ({
      ...valueState,
      content: {
        ...valueState?.content,
        description: value?.editor?.getData(),
      },
    }));
  };
  const handleCheckChange = (event) => {
    setValues({
      ...values, content: {
        ...values.content, [event.target.name]: event.target.checked,
      }
    });
  };
  const divisionChange = (value) => {
      setDivision(value);
  };
  const subjectChange = (value) => {
    setSubject(value);
  };

  const levelsChange = (options) => {
    setValues({
      ...values, content: {
        ...values.content, contentStudentLevels: options,
      }
    });
  };

  const chapterChange = (options) => {
    setChapters(options);
    setValues({
      ...values, content : {
        ...values.content, chapterContents:  [...values.content.chapterContents, ...[options.pop()]],
      }
    });
  };
  const selectedChaptersChange = (e, options) => {
    setValues({
      ...values, content: {
        ...values.content, chapterContents: options,
      },
    });
  };
  const instructorChange = (value) => {
    setTeacher((value))
    setValues({
      ...values, content: {
        ...values.content, instructor: value,
      }
    });
  };
  const scopeChange = (value) => {
    setScope(value)
    setValues({
      ...values, content: {
        ...values.content, scope: value,
      }
    });
  };
  const typeChange = (value) => {
    setType(value)
    setValues({
      ...values, content: {
        ...values.content, type: value,
      }
    });
  };
  const tabChange = (event, value) => {
    setTab(value)

  };

  const VideoChange = (files) => {
    if(files && files.length > 0) {
      setValues({
        ...values,
        file: files[0],
      });
    }
  };

  const attachmentChange = (files) => {
    if(files && files.length > 0) {
      setValues({
        ...values, content: {
          ...values.content, contentFiles: files,
        }
      });
    }
  };

  const traineeshipChange = (value) => {
    if(value){
      setValues({...values, traineeship: value});
      
    }
  };

  const EventChange = (value) => {
    if(value){
      setValues({...values, contentEvent: value});
    
    }};


  const selectedChaptersFormat = (values) => {
    let newArr = [];
    let newArr2 = [];
    let fileArr = [];
    let defaultSection = values.content.chapterContents[0]?.section;
    values.content.chapterContents.map(chapter => {
      newArr.push({
        'chapter': chapter.chapter ? chapter.chapter.id : chapter.id,
        'tab':   tab ? tab.id : chapter.tab ? chapter.tab.id : (defaultSection.tab && !chapter.section) ? defaultSection.tab.id :null,
        'position': chapter ? chapter?.position : 0,
        'section': chapter?.section? chapter?.section.id:null

      });
    });
    values.content.contentStudentLevels && values.content.contentStudentLevels.map(option=>{
      newArr2.push({'studentLevel':option.student_level? option.student_level.id:option.id})
    })
    values.content.contentStudentLevels = newArr2;
    values.content.chapterContents=newArr;
    values.content.instructor = values.content.instructor.id;
    values.content.scope = values.content.scope.id;
    values.content.type = values.content.type.id;
    values.contentEvent=values?.contentEvent

    if(values.content.contentFiles){
      values.content.contentFiles.map(option=>{
        fileArr.push({
          'position': 0,
          'file': {
            'file':option,
            'name': option.name,
            'attachment': 1,
            'solution': 0,
            'document': 0,
            'marks': 0,
            'homework': 0,
            'teacher': values.content.instructor.id
          },
        })
    })
      values.content.contentFiles = fileArr;
    }
    return values;
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function(key, index) {
      if (index == 0) {
        setAlertSeverity('error');
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
    }
  const getSubjects = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );
    const result = await api_get(`subjects?page=${page.page}&search=division.id:${division.id}${options}&searchJoin=and`);
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };

  const getChapters = async (search, prevData, page) => {
    const options = optionsParser(search, null, null, ['name']).replace( '&search=', ';' );;
    const result = await api_get(`chapters?page=${page.page}&search=subject.id:${subject.id}${options}&searchJoin=and`);
  
    return {
      options: [...result.payload],
      hasMore: result.meta.current_page !== result.meta.last_page,
      additional: {
        page: result.meta.current_page + 1,
      },
    };
  };
  const showAlertMessage = (message) => {
    setAlertSeverity('error');
    setAlertMessage(message);
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.content?.name) {
      return { success: false, 'message': 'Name is missing' };
    }
    if (values?.content?.name.length < 3 || values?.content?.name?.length > 2000) {
      return { success: false, 'message': 'Name must be between 3 and 2000 characters' };
    }
    if (values?.content?.description && (values?.content?.description?.length < 3 || values?.content?.description?.length > 2000)) {
      return { success: false, 'message': 'Description must be between 3 and 2000 characters' };
    }
    if (!values?.content?.instructor) {
      return { success: false, 'message': 'Instructor is missing' };
    }
    if (!values?.content?.scope) {
      return { success: false, 'message': 'Scope is missing' };
    }
    if (!values?.content?.type) {
      return { success: false, 'message': 'Type is missing' };
    }
   if (!values?.content?.chapterContents || values.content?.chapterContents?.length <= 0) {
      return { success: false, 'message': 'Chapters are missing' };
    }
   if(!edit && !tab?.id){
     return { success: false, 'message': 'Tab is missing' };
   }
   if(!values?.contentEvent && values?.content?.type?.id === 6){
     return { sucess:false,'message': 'Event is missing' }

   }
  };
  const clearDatas = (values) => {
    setValues({...values,
      content :{
        scope: scope,
        type: type,
        instructor: teacher,
        contentStudentLevels: '',
        chapterContents: '',
      }
    });
    setSubject([])
    setDivision([])
    setChapters('')
  };
  const handleSubmit = () => {
    const validation = formValidation(values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity('warning');
      setOpen(true);
    }
    else {
      const valuesState = selectedChaptersFormat(values);
      setLoading(true);
      //const requestValues = mapStateToRequest(values,['attachments','video']);
      const requestValues = mapStateToRequest(valuesState, ['file', 'content']);
       if (edit) {
         api_post_serialize(`content-videos/${id}`, requestValues, true).then((result) => {
           if(result.errors){
             showErrorMessage(result.errors)
           }else if(result?.code !== 200 && result?.code !== 201){
             showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
           } else {
             setAlertSeverity('success');
             setAlertMessage('video updated successfully');
             setOpen(true);
             setLoading(false);
             window.location.href = '/contents/content-videos';
           }
         });
       } else {
         api_post_serialize(`content-videos`, requestValues, true).then((result) => {
           if(result.errors){
             showErrorMessage(result.errors)
             clearDatas(values)
           }else if(result?.code !== 200 && result?.code !== 201){
             showAlertMessage(result?.message? result.message:'Technical error! Contact the Developers Team')
             clearDatas(values)

           } else {
             setAlertSeverity('success');
             setAlertMessage('video created successfully');
             setOpen(true);
             clearDatas(values)
             setLoading(false);
             window.location.href = '/contents/content-videos';
           }
         }).catch((error) => {
           throw new Error(error);
         });
       }
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Card className={classes.root}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.content.name || ''}
                  variant="outlined"
                  size="small"
                />
                <Box mt={2} mb={1}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Description
                  </Typography>
                </Box>
                <Paper variant="outlined">
                    
                {(edit && values?.content?.description  ) ? (
                    <DescriptionField
                      values={values.content}
                      handleBodyChange={handleDescriptionChange}
                      value={values?.content?.description}
                    />
                  ) : !edit ? (
                    <DescriptionField
                      values={values.content}
                      handleBodyChange={handleDescriptionChange}
                    />
                  ) : values.content?.description===null ?(
                    <DescriptionField
                    values={values.content}
                    handleBodyChange={handleDescriptionChange}
                  />
                  ): (values.content?.description==='')?<DescriptionField
                  values={values.content}
                  handleBodyChange={handleDescriptionChange}
         
                />:
              ""}
                </Paper>
                <Box mt={2} mb={1}>
                  <TextField
                      fullWidth
                      label={!edit ? "Url" : ""}
                      name="url"
                      onChange={urlChange}
                      required
                      value={values.url}
                      variant="outlined"
                      size="small"
                  />
                </Box>
                {
                  (edit || eventId)  &&
                  <Box mt={2} mb={1}>
                    <AsyncPaginate
                        loadOptions={getTraineeships}
                        onChange={traineeshipChange}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        value={values.traineeship}
                        placeholder="Traineeship"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                }
                    {(!eventIdShow) && values?.content?.type?.id === 6 && !eventId && <Box mt={2} mb={1}>
                    <AsyncPaginate
                        loadOptions={getEvents}
                        onChange={EventChange}
                        getOptionLabel={(option) => option.content.name}
                        getOptionValue={(option) => option.id}
                        value={values?.contentEvent || ''}
                        placeholder="Event"
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>}
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card>
                <CardHeader title="Upload Zone" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Video
                    </Typography>
                  </Box>
                  <FileDropZone values={values} setValues={setValues} onFileChange={VideoChange} isVideo={true}/>
                  <Box mt={2} mb={1}>
                    <Typography variant="subtitle2" color="textSecondary">
                      New Attachments
                    </Typography>
                  </Box>
                  <FilesDropzone setValues={setValues} values={values}  onFileChange={attachmentChange} isMultiple={true} isattachment={'true'} isPdf={true} edit={edit}/>
                  <FilesList attachments ={attachments} getElementDatas={getVideoDatas}/>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.root}>
              <CardHeader title="Basic" />
              <Divider className={classes.mtop0} />
              <CardContent className={classes.mtop0}>
                <Box mt={0}>
                  <AsyncPaginate
                    loadOptions={getScopes}
                    onChange={scopeChange}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    value={values.content.scope}
                    placeholder="Scope"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
                <Box mt={2}>
                  <AsyncPaginate
                      loadOptions={getContentTypes}
                      onChange={typeChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={values.content.type}
                      placeholder="Type"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
                <Box mt={2}>
                  <AsyncPaginate
                    loadOptions={getTeachers}
                    onChange={instructorChange}
                    getOptionLabel={(option) => option.name+' '+option.last_name}
                    getOptionValue={(option) => option.id}
                    value={values.content.instructor}
                    placeholder="Instructors"
                    additional={{
                      page: 1,
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        minHeight: '53px',
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#000',
                        fontSize: theme.typography.fontSize,
                        fontFamily: theme.typography.fontFamily,
                      }),
                    }}
                    menuPortalTarget={document.querySelector('body')}
                  />
                </Box>
              </CardContent>
            </Card>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Organize" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                      loadOptions={getDivisions}
                      onChange={divisionChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={division}
                      placeholder="Division"
                      additional={{
                        page: 1,tab
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                      isDisabled={division?false:true}
                      loadOptions={getSubjects}
                      onChange={subjectChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={subject}
                      placeholder="Subject"
                      noOptionsMessage={() => 'You may choose a division before'}
                      cacheUniqs={[division]}
                      additional={{
                       
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                      isDisabled={division?false:true}
                      loadOptions={getChapters}
                      isMulti
                      onChange={chapterChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      value={chapters}
                  
                      cacheUniqs={[subject]}
                      placeholder="Chapters"
                      noOptionsMessage={() => 'You may choose a subject before'}
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          minHeight: '53px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '#000',
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                        disabled={division?false:true}
                        id="combo-box-demo"
                        multiple
                        name='chapterContents'
                        disableCloseOnSelect
                        freeSolo
                        open={false}
                        onChange={selectedChaptersChange}
                        options={values?.content?.chapterContents || ''}
                        value={values?.content?.chapterContents}
                        getOptionLabel={(option) => (option.subject ?  (option?.subject?.division?.name +
                            '|' + option?.subject?.name + '|' + option.name) : (option?.chapter?.subject?.division?.name +'|' + option?.chapter?.subject?.name + '|' + option?.chapter?.name))||''}
                        getOptionSelected={(option, value) => value.id ===
                            option.id}
                        renderInput={(params) => (
                            <TextField {...params} label="Selected chapters"
                                       variant="outlined" fullWidth/>
                        )}
                        renderOption={(option, {selected}) => (
                            <React.Fragment>
                              <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                              />
                              {option.name}
                            </React.Fragment>
                        )}
                    />
                  </Box>
                  <Box mt={2}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={tabs}
                      value={tab? tab : tabSection}
                      disabled={tab &&edit? true: false}
                      getOptionLabel={(option) => option.name }
                      onChange={tabChange}
                      renderInput={(params) => <TextField {...params}
                        label={tabSection !== null && edit ? "This course has section": "Choose a tab"}
                        variant="outlined"/>}
                    />
                  </Box>
                  <Box mt={2}>
                    <AsyncPaginate
                        loadOptions={getLevels}
                        value={values.content.contentStudentLevels}
                        onChange={levelsChange}
                        getOptionLabel={(option) => option.student_level? option.student_level.name : option.name}
                        getOptionValue={(option) => option.student_level? option.student_level.id :option.id}
                        placeholder="Levels"
                        isMulti
                        additional={{
                          page: 1,
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '53px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            color: '#000',
                            fontSize: theme.typography.fontSize,
                            fontFamily: theme.typography.fontFamily,
                          }),
                        }}
                        menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.content?.active}
                          onChange={handleCheckChange}
                          value={values.content?.active}
                          name="active"
                          size="small"
                        />
                      }
                      label="Active ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.content?.isDraft}
                          onChange={handleCheckChange}
                          value={values.content?.isDraft}
                          name="isDraft"
                          size="small"
                        />
                      }
                      label="Draft ?"
                    />
                  </Box>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.content?.checkUserLevelRecording}
                          onChange={handleCheckChange}
                          value={values.content?.checkUserLevelRecording}
                          name="checkUserLevelRecording"
                          size="small"
                        />
                      }
                      label="checkUserLevelRecording ?"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button
            color="primary"
            variant="contained"
            onClick={(event) => handleSubmit(event)}
          >
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
 <LoadingApi open={loading} onClick={handleCloseLoading}/>
    </div>
  );
};


Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
loading: PropTypes.bool,
};

export default Form;
