//@flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { api_get, api_post, api_put } from "../../../utils/Api";
import { useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../../theme";
import mapStateToRequest from "../../../helpers/mapStateToRequest";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@material-ui/pickers";
import momentAdapter from "@material-ui/pickers/adapter/moment";

import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import LoadingApi from "components/LoadingApi/LoadingApi";
import {
  getGroups,
  getUsers,
  getTraineeships,
  getDivisions,
} from "../../../helpers/methods";
import AffiliationsNotes from "../AffiliationsView/AffiliationsNotes";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    overflow: "visible",
  },
  mtop: {
    marginTop: 24,
  },
  mtop0: {
    marginTop: 0,
  },
  content: {
    padding: 0,
  },

  uploadedFiles: {
    marginTop: 10,
  },
}));


const Form = (props) => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const classes = useStyles();
  const [currentDivision, setCurrentDivision] = useState(null)
  const [discountCodes, setDiscountCodes] = useState([])
  const [giftedCodes, setGiftedCodes] = useState([])

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      note: "",
      active: true,
      division: null,
      group: null,
      endDate: null,
      startDate: null,
      user: null,
      traineeship: null,
      startTime: moment("00:00:00", "HH:mm:ss"),
      endTime: moment("23:59:59", "HH:mm:ss"),
    },
    errors: {},
  });
  const [message, setAlertMessage] = useState("");
  const [getAffiliationNote, setGetAffiliationNote] = useState("");
  const [severity, setAlertSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [removedDiscountCode, removeDiscountCode] = useState(false)
  const [removedGiftCode, removeGiftCode] = useState(false)

  useEffect(() => {
    if (edit) {
      setLoading(true);
      api_get(`affiliations/${id}`).then((result) => {
        setLoading(false);
        removeDiscountCode(false)
        removeGiftCode(false)
        const { payload } = result;
        setGetAffiliationNote(payload?.affiliation_notes);
        setDiscountCodes(payload?.generated_discount_codes);
        setGiftedCodes(payload?.generated_gifts);
        setFormState({
          values: {
            endDate: payload?.end_date,
            startDate: moment(payload?.start_date, "YYYY/MM/DD HH:mm").format(
              "YYYY-MM-DD HH:mm"
            ),
            group: payload?.group,
            division: payload?.division,
            user: payload?.user,
            note: payload?.note,
            active: payload?.active,
            traineeship: payload?.traineeship,
            startTime: moment(payload?.start_time.slice(10), "HH:mm:ss"),
            endTime: moment(payload?.end_time.slice(10), "HH:mm:ss"),
          },
        });
      });
    }
  }, [removedGiftCode, removedDiscountCode]);
  
  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };
  const startDateChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          startDate: moment(value, "YYYY/MM/DD HH:mm").format(
            "YYYY-MM-DD HH:mm"
          ),
        },
      }));
    }
  };

  const endDateChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          endDate: moment(value, "YYYY/MM/DD HH:mm").format("YYYY-MM-DD HH:mm"),
        },
      }));
    }
  };

  const startTimeChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          startTime: moment(value, "HH:mm:ss"),
        },
      }));
    }
  };
  const endTimeChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          endTime: moment(value, "HH:mm:ss"),
        },
      }));
    }
  };
  const handleCheckChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.checked,
      },
    }));
  };
  const showErrorMessage = (errors) => {
    Object.keys(errors).map(function (key, index) {
      if (index == 0) {
        setAlertSeverity("error");
        setAlertMessage(errors[key][0]);
        setOpen(true);
      }
    });
  };

  const groupChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        group: value,
      },
    }));
  };

  const divisionChange = (value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        division: value,
      },
    }));
  };

  const traineeshipChange = (value) => {
    if (value) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          traineeship: value,
        },
      }));
    }
  };

  const userChange = (value) => {
    const userDivision = value?.division?.id
    setCurrentDivision(userDivision);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        user: value,
      },
    }));
  };
  const showAlertMessage = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpen(true);
  };
  const formValidation = (values) => {
    if (!values?.group) {
      return { success: false, message: "Group is missing" };
    }
    if (!values?.user) {
      return { success: false, message: "User is missing" };
    }
    if (!values?.startDate) {
      return { success: false, message: "StartDate is missing" };
    }
    if (!values?.endDate) {
      return { success: false, message: "EndDate is missing" };
    }
    if (!values?.note) {
      return {
        success: false,
        message: "Note is missing, please add a detailed description",
      };
    }
  };

  const handleSubmit = () => {
    const validation = formValidation(formState.values);
    if (validation) {
      setAlertMessage(validation.message);
      setAlertSeverity("warning");
      setOpen(true);
    } else {
      formState.values.startTime = moment(
        formState.values.startTime,
        "HH:mm:ss"
      ).format("HH:mm:ss");
      formState.values.endTime = moment(
        formState.values.endTime,
        "HH:mm:ss"
      ).format("HH:mm:ss");
      const requestValues = mapStateToRequest(formState.values);
      setLoading(true);
      requestValues.active = requestValues.active == 1 ? true : false;
      if (edit) {
        api_put(`affiliations/${id}`, requestValues).then((result) => {
          if (result.errors) {
            showErrorMessage(result.errors);
          } else if (result?.code !== 200 && result?.code !== 201) {
            showAlertMessage(
              result?.message
                ? result.message
                : "Technical error! Contact the Developers Team"
            );
          } else {
            setAlertSeverity("success");
            setAlertMessage("Affiliation updated successfully");
            setOpen(true);
            setLoading(false);
            window.location.href = "/finance/affiliations";
          }
        });
      } else {
        requestValues.division = currentDivision;
        api_post(`affiliations`, requestValues)
          .then((result) => {
            if (result.errors) {
              showErrorMessage(result.errors);
            } else {
              setAlertSeverity("success");
              setAlertMessage("Affiliation created successfully");
              setOpen(true);
              setLoading(false);
              window.location.href = "/finance/affiliations";
            }
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleRetreiveDiscountCode = () => {

    setLoading(true);
    api_get(`affiliations/${id}/retrieve-discount-code`).then((result) => {
        if (result.errors) {
          showErrorMessage(result.errors);
        } else if (result?.code !== 200 && result?.code !== 201) {
          showAlertMessage(
            result?.message
              ? result.message
              : "Technical error! Contact the Developers Team"
          );
        } else {
          setAlertSeverity("success");
          setAlertMessage("Code successfully retreived");
          removeDiscountCode(true)
          setOpen(true);
          setLoading(false);
        }
      });
   
  }

  const handleRetreiveGiftCode = () => {
    setLoading(true);
    api_get(`affiliations/${id}/retrieve-gift-code`).then((result) => {
      if (result.errors) {
        showErrorMessage(result.errors);
      } else if (result?.code !== 200 && result?.code !== 201) {
        showAlertMessage(
          result?.message
            ? result.message
            : "Technical error! Contact the Developers Team"
        );
      } else {
        setAlertSeverity("success");
        setAlertMessage("Code successfully retreived");
        removeGiftCode(true)
        setOpen(true);
        setLoading(false);
      }
    });
}
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <Grid container spacing={3}>
       
          <Grid item xs={12} lg={8}>
          <Box mt={3}>
              {
                  (discountCodes.length > 0 ||
                  giftedCodes.length > 0 )&&
                  <Card>

                  <CardHeader title="Retreive Codes" />
                  <Divider />
                  <CardContent>
                  <Box mt={0} mb={1}>
                      <Grid container spacing={3} justifyContent="center">
                      {
                          discountCodes.length > 0 &&
                          <Button
                          color="primary"
                          variant="outlined"
                          startIcon={<AddIcon />}
                          style={{ textTransform: "none", marginRight: '20px' }}
                          onClick={handleRetreiveDiscountCode}
                        >
                          Retreive Discount Code
                        </Button>
        
                      }
                  {
                      giftedCodes.length > 0 &&
                      <Button
                      color="secondary"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{ textTransform: "none" }}
                      onClick={handleRetreiveGiftCode}
                    >
                     Retreive Gift Code
                    </Button>
                  }
                      </Grid>
                    </Box>
                  </CardContent>
                  </Card>

              }
               
              </Box>
              <Box mt={3}>
            <Card className={classes.root}>
              <CardContent>
                <AsyncPaginate
                  loadOptions={getUsers}
                  value={formState.values.user}
                  onChange={userChange}
                  getOptionLabel={(option) =>
                    "[" +
                    option.id +
                    "]" +
                    " " +
                    option.name +
                    " " +
                    option.last_name +
                    " " +
                    (option?.phone ? option.phone : "") +
                    " " +
                    (option?.email ? "[" + option?.email + "]" : "")
                  }
                  getOptionValue={(option) => option}
                  placeholder="User"
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "53px",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#000",
                      fontSize: theme.typography.fontSize,
                      fontFamily: theme.typography.fontFamily,
                    }),
                  }}
                  menuPortalTarget={document.querySelector("body")}
                />
                <Box mt={3}>
                  <TextField
                    fullWidth
                    label="Note"
                    name="note"
                    onChange={handleChange}
                    required
                    value={formState.values.note}
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="Dates" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DateTimePicker
                            renderInput={(props) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                {...props}
                              />
                            )}
                            value={formState.values.startDate}
                            autoOk
                            ampm={false}
                            inputFormat="DD/MM/yyyy HH:mm"
                            label="Start date"
                            onChange={startDateChange}
                            name="startDate"
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <DateTimePicker
                            renderInput={(props) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                {...props}
                              />
                            )}
                            value={formState.values.endDate}
                            autoOk
                            ampm={false}
                            inputFormat="DD/MM/yyyy HH:mm"
                            label="End Date"
                            onChange={endDateChange}
                            name="endDate"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="Timeline" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <TimePicker
                            renderInput={(props) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                {...props}
                              />
                            )}
                            autoOk
                            ampm={false}
                            inputFormat="HH:mm:ss"
                            label="Start Time"
                            value={formState.values.startTime}
                            onChange={startTimeChange}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <LocalizationProvider dateAdapter={momentAdapter}>
                          <TimePicker
                            renderInput={(props) => (
                              <TextField
                                variant="outlined"
                                fullWidth
                                {...props}
                              />
                            )}
                            value={formState.values.endTime}
                            autoOk
                            ampm={false}
                            inputFormat="HH:mm:ss"
                            label="End Time"
                            onChange={endTimeChange}
                            name="endTime"
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <Card>
                <CardHeader title="Hessaty ?" />
                <Divider />
                <CardContent>
                  <Box mt={0} mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={12}>
                        <AsyncPaginate
                          loadOptions={getTraineeships}
                          onChange={traineeshipChange}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          placeholder={"Traineeships"}
                          isClearable={true}
                          additional={{
                            page: 1,
                          }}
                          value={formState.values?.traineeship || ""}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: "#000",
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              {" "}
              <AffiliationsNotes notes={getAffiliationNote} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box mt={0}>
              <Card className={classes.root}>
                <CardHeader title="Organize" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <AsyncPaginate
                      loadOptions={getGroups}
                      value={formState.values.group}
                      onChange={groupChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder="Groups"
                      additional={{
                        page: 1,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: "53px",
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: "#000",
                          fontSize: theme.typography.fontSize,
                          fontFamily: theme.typography.fontFamily,
                        }),
                      }}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </Box>
                  {edit &&
                      <Box mt={2}>
                        <AsyncPaginate
                            loadOptions={getDivisions}
                            value={formState.values.division}
                            onChange={divisionChange}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Division"
                            additional={{
                              page: 1,
                            }}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: "53px",
                              }),
                              placeholder: (base) => ({
                                ...base,
                                color: "#000",
                                fontSize: theme.typography.fontSize,
                                fontFamily: theme.typography.fontFamily,
                              }),
                            }}
                            menuPortalTarget={document.querySelector("body")}
                        />
                      </Box>
                  }

                </CardContent>
              </Card>
            </Box>

            <Box mt={3}>
              <Card className={classes.root}>
                <CardHeader title="Status" />
                <Divider className={classes.mtop0} />
                <CardContent className={classes.mtop0}>
                  <Box mt={0}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.values.active}
                          onChange={handleCheckChange}
                          value={formState.values.active}
                          name="active"
                          size="small"
                        />
                      }
                      label="Active"
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save details
          </Button>
        </Box>
      </form>
      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi open={loading} onClick={handleCloseLoading} />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;
