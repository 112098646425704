import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';


Bugsnag.start({
  apiKey: process.env.REACT_APP_ADMIN_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
  enabledReleaseStages: [ 'production', 'staging','local','development','test' ]
  // otherOptions: value
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);



ReactDOM.render(
  <Provider  store={store}>
    <ErrorBoundary>
    <App />
    </ErrorBoundary>
  </Provider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister();
