import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ArchiveIcon from "@material-ui/icons/Archive";
import AppsIcon from "@material-ui/icons/Apps";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import HighlightIcon from "@material-ui/icons/Highlight";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SettingsIcon from "@material-ui/icons/Settings";
import DnsIcon from "@material-ui/icons/Dns";
import Looks5Icon from "@material-ui/icons/Looks5";
import { Profile, SidebarNav } from "./components";
import Loading from "../../../../components/Loading/Loading";
import { useStore } from "../../../../contexts/JWTAuthContext";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { SettingsApplications } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    //width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(9) + 5,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, Click, sidebar, ...rest } = props;
  const { user } = useStore();
  const classes = useStyles();
  const crmUrl = process.env.REACT_APP_CRM_URL;
  const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;
  const statisticsUrl = process.env.REACT_APP_STATISTICS_URL;

  const pages = [
    // {
    //   title: 'Dashboard',
    //   href: '/dashboard',
    //   icon: <DashboardIcon/>,
    // },

    {
      title: "Users",
      icon: <PeopleIcon />,
      children: [
        {
          title: "Students",
          href: "/users/students",
          roleName: "users_list",
        },
        {
          title: "Teachers",
          href: "/users/teachers",
          roleName: "users_list",
        },
        {
          title: "Business",
          href: "/users/business",
          roleName: "users_list",
        },
        {
          title: "Parents",
          href: "/users/parent-users",
        },
        {
          title: "Approval Requests",
          href: "/users/parent-user-children",
        },
        {
          title: "User Borne",
          href: "/users/user-borne",
        },
      ],
    },







    // {
    //   title: 'Statistics',
    //   icon: <BarChartIcon/>,
    //   children: [
    //     {
    //       title: 'Sales Statistics',
    //       href: '/statistics/sales',
    //     },
    //     {
    //       title: 'Division Statistics',
    //       href: '/statistics/divisions',
    //     },
    //     {
    //       title: 'Student Statistics',
    //       href: '/statistics/students',
    //     },
    //   ],
    // },
    {
      title: "Finance",
      icon: <AttachMoneyIcon />,
      children: [
        {
          title: "Payment Agreements",
          href: "/finance/payment-agreements",
        },
        {
          title: "Payment Slices",
          href: "/finance/payment-agreement-slices",
        },
        {
          title: "Affiliations",
          href: "/finance/affiliations",
        },
        {
          title: "Affiliations Evolution",
          href: "/finance/affiliations-evolution",
        },
        {
          title: "Buy For Student",
          href: "/finance/buy-for-user",
        },
        {
          title: "Codes",
          href: "/finance/finance-codes",
        },
        {
          title: "Autogenerated Codes",
          href: "/finance/codes",
        },
        {
          title: "Checks",
          href: "/finance/checks",
        },
        {
          title: "Points Conversions",
          href: "/users/points-conversions",
        },
        {
          title: "Point Transfers",
          href: "/users/point-transfers",
        },
        {
          title: "Company Accounts",
          href: "/finance/company-accounts",
        },
        {
          title: "Admin Config",
          href: "/finance/config",
        },
        {
          title: "D17 Orders",
          href: "/finance/d17-orders",
        },
        {
          title: "Poste Payments",
          href: "/finance/poste-payments",
        },
        {
          title: "Ooredoo Orders",
          href: "/finance/Ooredoo-orders",
        },
      ],
    },

    {
      title: "Offer Settings",
      icon: <LocalOfferIcon />,
      children: [
        {
          title: "Offers",
          href: "/offers/offers",
        },
        {
          title: "Offer Special Prices",
          href: "/offers/offer-special-prices",
        },
        {
          title: "Offer Group Links",
          href: "/offers/offer-group-links",
        },
        {
          title: "Offer Token",
          href: "/offers/offer-token",
        },
      ],
    },

    {
      title: "Marketing",
      icon: <HighlightIcon />,
      children: [
        {
          title: "Offices",
          href: "/marketing/offices",
        },
        {
          title: "Pubs Cards",
          href: "/marketing/pubs-cards",
        },

        {
          title: "Messages",
          href: "/marketing/messages",
          //   href: '/under-construction',
        },
        {
          title: "Sms",
          href: "/marketing/sms",
        },
        {
          title: "Called user",
          href: "/marketing/called-user/yesteday",
          // href: '/under-construction',
        },
        {
          title: "Discount Codes",
          href: "/marketing/discount-codes",
        },
      ],
    },
    {
      title: "Gift",
      icon: <CardGiftcardIcon />,
      children: [
        {
          title: "Gift",
          href: "/gift/gift",

        },
        {
          title: "Gift Request",
          href: "/gift/gift-request",

        },
      ],
    },
    {
      title: "Contents",
      icon: <FindInPageIcon />,
      children: [
        {
          title: "Divisions",
          href: "/contents/divisions",
        },
        {
          title: "Subjects",
          href: "/contents/subjects",
        },
        {
          title: "Domains",
          href: "/contents/domains",
        },
        {
          title: "Chapters",
          href: "/contents/chapters",
        },
        {
          title: "Events",
          href: "/contents/content-events",
        },
        {
          title: "Videos",
          href: "/contents/content-videos",
        },
        {
          title: "Magazines",
          href: "/contents/magazines",
        },
        {
          title: "Exams",
          href: "/contents/content-exams",
        },
        {
          title: "Qcm",
          href: "/contents/qcm",
          //roleName: 'CONTENTS_VIEW',
        },
        {
          title: "Webinar Accounts",
          href: "/contents/webinar-accounts",
          roleName: "WebinarAccounts_list",
        },
        {
          title: "Scopes",
          href: "/contents/content-scopes",
        },
        {
          title: "Tasks",
          href: "/contents/tasks",
        },
        {
          title: "Video Reports",
          href: "/contents/video-reports",
        },
        {
          title: "Recordings",
          href: "/contents/recordings",

        },
      ],
    },
    {
      title: "Hessaty",
      icon: <Looks5Icon />,
      children: [
        {
          title: "Hessaty Chapters",
          href: "/hessaty/traineeships",
        },
        {
          title: "Hessaty Events",
          href: "/hessaty/content-events",
        },
      ],
    },
    {
      title: "Access Control",
      icon: <DnsIcon />,
      children: [
        {
          title: "Groups",
          href: "/acl/groups",
        },
        {
          title: "User Tokens",
          href: "/acl/access-user-event-token",
        },
      ],
    },

    {
      title: "Archives",
      icon: <ArchiveIcon />,
      children: [
        {
          title: "Deleted Contents",
          href: "/archives/contents",
        },
        {
          title: "Deleted Chapters",
          href: "/archives/chapters",
        },
        {
          title: "Deleted Subjects",
          href: "/archives/subjects",
        },
        {
          title: "Deleted Users",
          href: "/archives/Users",
        },
        {
          title: "Deleted Codes",
          href: "/archives/codes",
        },
        {
          title: "Deleted Affiliations",
          href: "/archives/affiliations",
        },
        {
          title: "Deleted Payments",
          href: "/archives/payment-agreements",
        },
      ],
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      children: [
        {
          title: "Admin Users",
          href: "/settings/admin-users",
        },
        {
          title: "Admin Roles",
          href: "/settings/roles",
        },
        // {
        //   title: 'Admin Permissions',
        //   href: '/settings/permissions',
        // },
        {
          title: "User Types",
          href: "/settings/user-types",
        },
        {
          title: "Student Levels",
          href: "/settings/student-levels",
        },
        {
          title: "Student Level Configs",
          href: "/settings/student-level-configs",
        },
        {
          title: "Institutes",
          href: "/settings/institutes",
        },
        {
          title: "Delegations",
          href: "/settings/delegations",
        },
        {
          title: "Report  Reason",
          href: "/settings/video-reports-reasons",
        },
        {
          title: "Help  Videos",
          href: "/settings/help-videos",
        },
      ],
    },
    {
      title: "User Settings",
      icon: <SettingsApplications />,
      children: [
        {
          title: "MarkSheets",
          href: "/user-settings/mark-sheets",
        },

        {
          title: "Edit Divisions",
          href: "/user-settings/edit-divisions",
        },
        {
          title: "Banned Users",
          href: "/user-settings/banned-users",
        },
        {
          title: "Segmentation",
          href: "/user-settings/segments",
        },
        {
          title: "User Levels",
          href: "/user-settings/student-level-user",
        },
      ],
    },
    {
      title: "Teacher Settings",
      icon: <SettingsApplications />,
      children: [
        {
          title: "Penalties",
          href: "/teacher-settings/penalties",
        },
        {
          title: "Penalty Details",
          href: "/teacher-settings/penalty-details",
        },
        {
          title: "Penalty Units",
          href: "/teacher-settings/penalty-units",
        },
        {
          title: "Event Penalties",
          href: "/teacher-settings/event-penalties",
        },
      ],
    },
    {
      title: "Notifications",
      icon: <NotificationsActiveIcon />,
      children: [
        {
          title: "Popups",
          href: "/notifications/popups",
        },
      ],
    },
    {
      title: "Developer",
      icon: <ErrorIcon />,
      children: [
        {
          title: "Logs",
          href: "/developer/logs",
        },
        {
          title: "Audit",
          href: "/developer/audit",
        },
        {
          title: "Switch Logs",
          href: "/developer/switch-log",
        },
      ],
    },
    {
      title: "Services",
      icon: <AppsIcon />,
      children: [
        {
          title: "Crm",
          href: crmUrl,
          external: true,
          roleName: "*",
        },

        {
          title: "Analytics",
          href: analyticsUrl,
          external: true,
          roleName: "*",
        },
        {
          title: "Statistics",
          href: statisticsUrl,
          external: true,
          roleName: "*",
        },
        // {
        //   title: "Softy-Popups",
        //   href: statisticsUrl,
        //   isButton: true,
        //   roleName: "*",
        // },
      ],
    },
    /*   {
      title: 'History',
      icon: <HistoryIcon/>,
      children: [
        {
          title: 'audit',
          href: '/history/audit',
        },
        {
          title: 'Log',
          href: '/logs',
        },
      ],
    },*/
  ];
  if (!user) {
    return <Loading />;
  }

  return (
    <Drawer
      ModalProps={{ onBackdropClick: onClose }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={open}
      open={open}
      variant={variant}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.toolbar}>
          <IconButton onClick={onClose}></IconButton>
        </div>
        <Profile profil={open} />

        <Divider className={classes.divider} />
        <SidebarNav
          Click={Click}
          sidebar={open}
          className={classes.nav}
          pages={pages}
          user={user}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
